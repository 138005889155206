/*global fbq*/

import React, { useCallback, useState } from 'react';
import './App.css';

const trackedSocialLink = [];
const trackSocialLink = name => () => {
  if (trackedSocialLink.includes(name)) {
    return;
  }
  trackedSocialLink.push(name);
  fbq('trackCustom', 'SocialLink', {name});
};

const trackedPage = [];
const trackPage = page => {
  if (trackedPage.includes(page)) {
    return;
  }
  trackedPage.push(page);
  fbq('trackCustom', 'PageLink', {page});
};

const Home = () => {
  return (
    <div className="container">
      <p className="wl-social">
        <a className="has-text-dark" onClick={trackSocialLink('linkedin')} href="https://www.linkedin.com/in/wilsonlaikh/" target="_blank" rel="noopener noreferrer">
          <span className="icon is-medium">
            <span className="fa-stack">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fab fa-linkedin-in fa-stack-1x fa-inverse"></i>
            </span>
          </span>
        </a>
        <a className="has-text-dark" onClick={trackSocialLink('github')} href="https://github.com/wilsoncode" target="_blank" rel="noopener noreferrer">
          <span className="icon is-medium">
            <span className="fa-stack">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fab fa-github fa-stack-1x fa-inverse"></i>
            </span>
          </span>
        </a>
        <a className="has-text-dark" onClick={trackSocialLink('twitter')} href="https://twitter.com/keenhoong88" target="_blank" rel="noopener noreferrer">
          <span className="icon is-medium">
            <span className="fa-stack">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fab fa-twitter fa-stack-1x fa-inverse"></i>
            </span>
          </span>
        </a>
        <a className="has-text-dark" onClick={trackSocialLink('email')} href="mailto:keenhoong.lai@gmail.com">
          <span className="icon is-medium">
            <span className="fa-stack">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fas fa-envelope-open fa-stack-1x fa-inverse"></i>
            </span>
          </span>
        </a>
      </p>
      <h1 className="title">
        wilson lai
      </h1>
      <h2 className="subtitle">
        lead, software engineering<span className="wl-typing">_</span>
      </h2>
    </div>
  );
};

const About = () => {
  return (
    <div className="container">
      <h1 className="title">
        about me
      </h1>
      <h2 className="subtitle">
        <p>I have a little over 9 years of experience as a software engineer, and have worked at both small startup and large company with offices across multiple regions. While I’m a proficient full-stack developer, my expertise is in building scalable backend services (API services, service integrations and async mechanism).</p>
        <br/>
        <p>I love building things (even involve myself in building a house for <em>orang asli</em> - aborigines people). As for software development I’m most attracted to building software products from the ground up, solving real customer problems with business justification. I <strong>seek to understand before making well-reasoned design decisions and tradeoffs</strong> for solutions.</p>
        <br/>
        <p>I’m always interested in becoming a better technology leader and meeting interesting people across different fields of work.<span className="wl-typing">_</span></p>
      </h2>
    </div>
  );
};

const App = () => {
  const [currentPage, setCurrentPage] = useState('home');

  const goto = useCallback(page => e => {
    e.preventDefault();
    if (page === currentPage) {
      return;
    }
    trackPage(page);
    setCurrentPage(page);
  }, [currentPage]);

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 'home':
        return <Home />;
      case 'about':
        return <About />;
      default:
        return <Home />;
    }
  };

  return (
    <section className="hero is-fullheight is-unselectable">
      <div className="hero-head">
        <div className="container is-block has-text-right is-size-5 wl-menu">
            <a className="has-text-dark" href="/" onClick={goto('home')}>home</a>
            <a className="has-text-dark" href="/" onClick={goto('about')}>about</a>
          </div>
      </div>
      <div className="hero-body">
        {renderCurrentPage()}
      </div>
    </section>
  );
};

export default App;
